<template>
  <div>
    <div class="four-point-plot no-top-margin-all-descendants" ref="graph"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: 'FourPointPlot',
  props: {
    data: Array,
    config: Object,
    height: Number,
    width: Number
  },
  mounted() {
    if (this.data) {
      this.generateApexChartsGraph();
    }

  },
  methods: {
    generateApexChartsGraph() {
      let colours = ['rgb(1, 115, 185)', 'rgb(249, 194, 10)', 'rgb(241, 93, 34)', 'rgb(96, 63, 153)'];
      let options = {
        chart: {
          type: 'line',
          zoom: { enabled: false },
          height: this.height,
          toolbar: {
            offsetY: -24.5,
            offsetX: -30,
            export: {
              csv: {
                filename: 'CLAD_4_Point_Plots'
              },
              svg: {
                filename: 'CLAD_4_Point_Plots'
              },
              png: {
                filename: 'CLAD_4_Point_Plots'
              }
            }
          }
        },
        grid: {
          show: true,
          borderColor: '#e0e0e0',
          strokeDashArray: 0, // 0 for solid lines
          position: 'back', 
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: { enabled: false },
        series: this.data,
        legend: {
          labels: {
            colors: colours
          },
          markers: {
            fillColors: colours
          }
        },
        stroke: {
          width: 2,
          colors: colours
        },
        markers: {
          size: 3,
          colors: colours
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: value => {
              return value.toFixed(1);
            },
            style: {
              fontSize: 9
            }
          }
        },
        xaxis: {
          tickAmount: 3,
          stepSize: 90,
          max: 360,
          labels: {
            rotate: 0,
            style: {
              fontSize: 9
            }
          }
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex}) => {
            return `
              <div>
                <span style="color:${colours[seriesIndex]};background-color:rgb(236, 239, 241);border:1.5px solid rgb(182, 195, 202);" class="p-2">${series[seriesIndex][dataPointIndex].toFixed(3)}</span>
              </div>
            `;
          }
        }
      };

      let graph = new ApexCharts(this.$refs.graph, options);
      graph.render();
      this.$emit('graph-generated', graph);
    },
  }
};
</script>

<style>
  .four-point-plot {
    width: 375px;
    background-color: #fff !important;
  }
</style>
